/* style datepicker */

.react-datepicker-popper {
  left: -90px !important;
}

.react-datepicker__triangle {
  display: none !important;
}

.brand-cal {
  border: none !important;
  font-family: var(--font-rubik) !important;
  font-size: 14px !important;
  background-color: #FBFBFB !important;
  box-shadow: 0px 2px 20px 0px #00000014;
  border-radius: 20px !important;
}

.react-datepicker__month-container {
  padding: 8px;
}

.brand-cal .react-datepicker__header {
  border-radius: 20px !important;
  background-color: #FBFBFB !important;
  color: #0F305E !important;
  border: none !important;
  padding: 0 !important;
}

.react-datepicker__day-names {
  margin-top: 17.5px !important;
  font-size: 12px !important;
}

.react-datepicker__current-month {
  color: #0F305E !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.react-datepicker__day-name {
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 14.22px !important;
  color: #0F305E !important;
  padding: 0px 17px !important;
  margin: 7px !important;
}

.react-datepicker__navigation {
  top: 14px !important;
}

.react-datepicker__navigation-icon::before {
  border-color: transparent !important;
  border-width: 0px !important;
  border-radius: 50% !important;
  width: 24px !important;
  height: 24px !important;
  top: 0px !important;
}

.react-datepicker__navigation-icon--next::before {
  transform: none !important;
  background-image: url(/public/assets/icons/datepicker_arrow_right.svg) !important;
}

.react-datepicker__navigation--next {
  right: 13px !important;
  top: 14px !important;
}

.react-datepicker__navigation-icon--previous::before {
  transform: none !important;
  background-image: url(/public/assets/icons/datepicker_arrow_left.svg) !important;
}

.react-datepicker__navigation--previous {
  left: 13px !important;
  top: 14px !important;
}

.react-datepicker__day {
  width: 36px !important;
  height: 36px;
  font-size: 14px !important;
  line-height: normal !important;
  padding: 10px;
  justify-content: center;
  align-items: center;
  margin: 7px !important;
  color: #0F305E !important;
}

.react-datepicker__day:hover {
  border-radius: 50% !important;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__day--selected {
  background-color: #689598 !important;
  color: white !important;
  border-radius: 50% !important;

}

.react-datepicker__day--outside-month {
  color: #FBFBFB !important;
  background-color: #FBFBFB !important;

}

.react-datepicker__day--keyboard-selected {
  background-color: transparent !important;
}


.react-datepicker__input-container>input {
  background: #FFFFFF !important;
  /* max-width: 230px !important; */
  width: 100%;
  height: 40px;
  padding: 8px 16px 8px 19px !important;
  box-shadow: 0px 2px 20px 0px #00000014 !important;
  border-radius: 12px !important;
  font-size: 16px;
  color: #004489;
  border: 1px solid #004489;
}

.react-datepicker__input-container::after {
  content: "";
  background-image: url(/public/assets/icons/datepicker.svg) !important;
  background-repeat: no-repeat;
  position: absolute;
  width: 24px;
  height: 24px;
  top: 8px;
  left: 20px;
}


.chakra-form-control[data-invalid] .react-datepicker__input-container>input {
  border: 1px solid #F4354B;
}

.react-datepicker__input-container>input::placeholder {
  color: #7E99C0;
}

.react-datepicker-ignore-onclickoutside {
  outline: none !important;
}

.react-datepicker__input-container>input:focus-visible {
  outline: none !important;
}

/* range datepicker */

.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  background-color: #EDE6F6 !important;
  border-radius: 50% !important;
  color: #0F305E !important;
  font-weight: 400 !important;
}

.react-datepicker__day--selecting-range-end {
  background-color: #EDE6F6 !important;
  border-radius: 50% !important;
  color: #0F305E !important;
  font-weight: 400 !important;
}